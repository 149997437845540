<template>
  <q-intersection
    class="col-10 q-px-lg items-end justify-center row text-center"
    once
    :style="`min-height: ${$q.screen.gt.sm ? 110 : 170}px`"
    @visibility="onVisibleTitle()"
  >
    <div ref="motionRefTitle">
      <h2 :class="{ 'tip tip-text-h2': isTip }" class="q-mb-none">
        {{ title }}
      </h2>
    </div>
  </q-intersection>

  <q-intersection
    class="col-10 q-px-lg text-center"
    once
    :style="`min-height: ${$q.screen.gt.sm ? 62 : 85}px`"
    @visibility="onVisibleSubtitle()"
  >
    <div ref="motionRefSubtitle">
      <h3 class="text-h6" style="line-height: 1.125">
        {{ subtitle
        }}<span v-if="showTip" class="relative-position tip tip-text-h6"
          >HipHouse.</span
        >
      </h3>
    </div>
  </q-intersection>
</template>

<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useToUp } from "src/composables/animation/useToUp";
import { usePreferencesStore } from "src/stores/preferences";

defineOptions({ name: "SectionTitles" });

const props = defineProps({
  title: { type: String, default: "" },
  isTip: { type: Boolean, default: false },
  showTip: { type: Boolean, default: false },
  subtitle: { type: String, default: "" },
});

const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const motionRefSubtitle = ref(null);
const motionRefTitle = ref(null);

const { setupMotion: setupMotionSubtitle } = useToUp(motionRefSubtitle, 0);
const { setupMotion: setupMotionTitle } = useToUp(motionRefTitle, 0);

const onVisibleTitle = () => {
  if (motion.value) {
    const motionInstance = setupMotionTitle();
    motionInstance.apply("enter");
  }
};

const onVisibleSubtitle = () => {
  if (motion.value) {
    const motionInstance = setupMotionSubtitle();
    motionInstance.apply("enter");
  }
};
</script>
