<template>
  <q-page class="column full-height q-pb-xl">
    <hero-swiper
      v-if="data.heroSwiper?.images?.length"
      :slides="data.heroSwiper.images"
    />
    <sections-component :sections="data.sections" />
    <cta-banner />
    <q-page-scroller
      position="bottom-right"
      :scroll-offset="2048"
      :offset="[18, 18]"
    >
      <q-btn fab icon="keyboard_arrow_up" color="accent" unelevated />
    </q-page-scroller>
  </q-page>
</template>

<script setup>
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { storeToRefs } from "pinia";
import { useMeta } from "quasar";
import { queryHome } from "src/api/sanity/queries/home";
import { i18n } from "src/boot/i18n";
import { queryClient } from "src/boot/vueQuery";
import { useDehydrateQueryClient } from "src/composables/useDehydrateQueryClient";
import { useHydrateQueryClient } from "src/composables/useHydrateQueryClient";
import { useQueryErrorHandler } from "src/composables/useQueryErrorHandler";
import { useUpdateStoreFromQuery } from "src/composables/useUpdateStoreFromQuery";
import { useHomeStore } from "src/stores/home";
import CtaBanner from "src/components/cta/CtaBanner.vue";
import HeroSwiper from "src/components/hero/HeroSwiper.vue";
import SectionsComponent from "src/components/sections/SectionsComponent.vue";

const QKEY = "IndexPage";

defineOptions({
  name: QKEY,
  async preFetch({ store, ssrContext }) {
    const homeStore = useHomeStore(store);
    try {
      await queryClient.prefetchQuery({
        queryKey: [QKEY],
        queryFn: () => queryHome(),
      });
      homeStore.home = queryClient.getQueryData([QKEY]);
      useDehydrateQueryClient(ssrContext);
    } catch (e) {
      console.error("Failed to execute preFetch queryPage", e);
    }
  },
});

const { t } = i18n.global;
const homeStore = useHomeStore();
const { home } = storeToRefs(homeStore);

const metaDescription = t("meta.description");
const metaImage = t("meta.image");
const metaTitle = t("meta.title");

const { data, error, isError, isFetching, isLoading, isPending } = useQuery({
  queryKey: [QKEY],
  queryFn: () => queryHome(),
  initialData: home?.value,
  placeholderData: keepPreviousData,
});

useMeta(() => {
  return {
    meta: {
      description: {
        name: "description",
        content: metaDescription,
      },
      ogDescription: {
        property: "og:description",
        content: metaDescription,
      },
      ogTitle: {
        property: "og:title",
        content: metaTitle,
      },
      ogImage: {
        property: "og:image",
        content: metaImage,
      },
    },
    title: metaTitle,
  };
});

useQueryErrorHandler(error, isError);
useUpdateStoreFromQuery(data, home);
useHydrateQueryClient();
</script>
