<template>
  <div class="full-width justify-center relative-position row">
    <div class="full-width text-center">
      <svg style="height: 16px; width: 32px">
        <use href="#tipAccent" />
      </svg>
    </div>
    <section-titles :subtitle="rows.subtitle" :title="rows.title" />
    <div class="full-width q-mt-md q-pr-xl">
      <div
        v-for="(row, index) in rows.row"
        :key="row.image.asset._id"
        :class="[{ reverse: index % 2 !== 0 && $q.screen.gt.sm }]"
        class="full-width items-center justify-center q-col-gutter-xl row"
      >
        <q-intersection
          class="col-xs-12 col-md-6 flex flex-center"
          once
          @visibility="onVisibleTitle(index)"
        >
          <div
            :ref="(el) => (titleRefs[index] = el)"
            :class="$q.screen.gt.sm ? 'q-pa-xl text-h4' : 'q-pb-xl text-h5'"
            style="line-height: 1.125"
          >
            {{ row.title }}
          </div>
        </q-intersection>
        <q-intersection
          :class="{ 'order-first q-mt-md': $q.screen.lt.md }"
          class="col-xs-12 col-md-4 relative-position"
          once
          :style="`min-height: ${$q.screen.gt.sm ? 5 : 3}00px`"
          @visibility="onVisibleImage(index)"
        >
          <div
            class="relative-position"
            :ref="(el) => (imageRefs[index] = el)"
            :style="`height: ${$q.screen.gt.sm ? 5 : 3}00px`"
          >
            <image-component :image="row.image" />
          </div>
        </q-intersection>
      </div>
    </div>
    <div
      :class="{ 'q-pt-xl': $q.screen.gt.sm }"
      class="col-12 justify-center q-my-xl row"
    >
      <div class="column">
        <dialog-button />
        <div class="text-center" style="width: 208px">
          <svg class="q-mt-lg" style="height: 16px; width: 32px">
            <use href="#tipAccent" />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, reactive } from "vue";
import { storeToRefs } from "pinia";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { useToUp } from "src/composables/animation/useToUp";
import { usePreferencesStore } from "src/stores/preferences";
import DialogButton from "src/components/dialog/DialogButton.vue";
import ImageComponent from "src/components/image/ImageComponent.vue";
import SectionTitles from "src/components/section/SectionTitles.vue";

defineOptions({ name: "RowsBlock" });

const props = defineProps({
  rows: {
    type: Object,
    default: () => ({
      title: "",
      subtitle: "",
      row: new Array(3).fill({ title: "", image: {} }),
    }),
  },
});

const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const titleRefs = reactive(new Array(props.rows.row.length));
const imageRefs = reactive(new Array(props.rows.row.length));

const onVisibleImage = async (index) => {
  await nextTick();
  if (motion.value && imageRefs[index]) {
    const motionInstance = useToUp(imageRefs[index], 0);
    motionInstance.setupMotion().apply("enter");
  }
};

const onVisibleTitle = async (index) => {
  await nextTick();
  if (motion.value && titleRefs[index]) {
    const motionInstance = useFromLeft(titleRefs[index]);
    motionInstance.setupMotion().apply("enter");
  }
};
</script>
