import { sanityClient } from "src/boot/sanity";

export const homeQuery = `*[_type == "home"] {
  title,
  heroSwiper {
    images[] {
      asset->{
        _id,
        url
      }
    },
  },
  sections[] {
    _type,
    ...,
     _type == "cardBlocks" => {
      title,
      subtitle,
      cards[] {
        body,
        iconPath[],
        image {
          asset->
        }
      }
    },
    _type == "rows" => {
      title,
      subtitle,
      row[] {
        title,
        image {
          asset->
        }
      }
    }
  }
}[0]`;

export const queryHome = async () => {
  try {
    const result = await sanityClient.fetch(homeQuery);
    return result;
  } catch (error) {
    console.error("Failed to execute queryHome", error);
    throw error;
  }
};
