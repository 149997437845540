<template>
  <div class="full-width relative-position row">
    <div
      class="col-xs-12 col-md-7 offset-md-5 relative-position"
      :style="`height: ${$q.screen.gt.sm ? 6 : 3}00px`"
    >
      <swiper-component
        v-if="slides.length"
        :auto="swiperAuto"
        :slides="slides"
        :slides-per-view="$q.screen.lt.md ? 2 : 3"
        :style="`height: ${$q.screen.gt.sm ? 6 : 3}00px`"
      />
      <div
        v-if="$q.screen.lt.md"
        class="absolute-center full-width"
        style="z-index: 1"
      >
        <div class="full-width q-mt-xl q-pt-xl row" style="overflow: hidden">
          <marquee-component />
        </div>
      </div>
    </div>
    <div
      :class="$q.screen.lt.md ? 'col-12 order-first' : 'absolute-left '"
      class="items-center row"
      style="z-index: 1"
    >
      <div
        :class="$q.screen.gt.sm ? 'q-mb-xl  q-px-xl' : 'q-px-md'"
        class="col-xs-12 col-md-4 q-pb-xl"
      >
        <h1 class="text-h3" ref="motionRef">
          {{ t("home.title.string")
          }}<router-link :to="{ name: 'page', params: { slug: 'about' } }">{{
            t("home.title.link")
          }}</router-link
          >.
        </h1>
        <div class="row">
          <div class="col-12">
            <dialog-button />
          </div>
          <div class="col-12 text-center" style="width: 208px">
            <svg class="q-mt-lg" style="height: 16px; width: 32px">
              <use href="#tipAccent" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="$q.screen.gt.sm"
      class="absolute-bottom full-width"
      style="z-index: 1"
    >
      <div class="full-width q-pb-lg row" style="overflow: hidden">
        <router-link
          class="text-no-underline"
          :to="{ name: 'page', params: { slug: 'services' } }"
        >
          <marquee-component />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useFromLeft } from "src/composables/animation/useFromLeft";
import { usePreferencesStore } from "src/stores/preferences";
import DialogButton from "src/components/dialog/DialogButton.vue";
import MarqueeComponent from "src/components/marquee/MarqueeComponent.vue";
import SwiperComponent from "src/components/swiper/SwiperComponent.vue";

defineOptions({ name: "HeroSwiper" });

const props = defineProps({
  slides: { type: Array, default: () => [] },
});

const { t } = i18n.global;
const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const motionRef = ref(null);
const motionInstance = ref(null);
const swiperAuto = ref(true);

const { setupMotion } = useFromLeft(motionRef);

const onMouseEnter = () => {
  swiperAuto.value = false;
};

const onMouseLeave = () => {
  swiperAuto.value = true;
};

onMounted(() => {
  if (motion.value) {
    motionInstance.value = setupMotion();
    motionInstance.value.apply("enter");
  }
});
</script>
