<template>
  <div>
    <component
      v-for="section in sections"
      :block="section"
      :key="section._key"
      :list="section"
      :rows="section"
      :is="isComponent(section._type)"
    />
  </div>
</template>

<script setup>
import CardBlocks from "src/components/card/CardBlocks.vue";
import ListBlock from "src/components/list/ListBlock.vue";
import RowsBlock from "src/components/rows/RowsBlock.vue";

defineOptions({ name: "SectionsComponent" });

const props = defineProps({ sections: { type: Array, default: () => [] } });

const isComponent = (type) => {
  switch (type) {
    case "cardBlocks":
      return CardBlocks;
    case "listBlock":
      return ListBlock;
    case "rows":
      return RowsBlock;
    default:
      break;
  }
};
</script>
