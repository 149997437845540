import { ref, onMounted } from "vue";

export function useMarquee(speed) {
  const marqueeRef = ref(null);

  const marquee = (element, speed) => {
    let i = 0;
    const elementWidth = element.scrollWidth;
    const scroll = () => {
      i -= speed;
      if (i <= -elementWidth) {
        i = 0;
      }
      element.style.transform = `translateX(${i}px)`;
      requestAnimationFrame(scroll);
    };
    element.innerHTML += element.innerHTML;
    scroll();
  };

  onMounted(() => {
    if (marqueeRef.value) marquee(marqueeRef.value, speed);
  });

  return { marqueeRef };
}
