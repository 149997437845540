<template>
  <div v-if="settings.tags.length">
    <div class="q-gutter-lg no-wrap row" ref="marqueeRef">
      <q-chip
        v-for="tag in settings.tags"
        color="secondary"
        :key="tag.title"
        :label="tag.title"
        size="lg"
        square
        :text-color="textColor"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMarquee } from "src/composables/useMarquee";
import { isColorDark } from "src/composables/useRandomPalette";
import { useSettingsStore } from "src/stores/settings";

defineOptions({ name: "MarqueeComponent" });

const settingsStore = useSettingsStore();
const { selectedPalette, settings } = storeToRefs(settingsStore);
const { marqueeRef } = useMarquee(1.8);

const textColor = ref("dark");

const handleTextColor = () => {
  textColor.value = isColorDark(
    selectedPalette.value.secondary?.hex || "#000000"
  )
    ? "white"
    : "dark";
};

watch(selectedPalette, handleTextColor, { deep: true });

onMounted(() => {
  handleTextColor();
});
</script>
