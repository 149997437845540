<template>
  <div class="column">
    <q-intersection
      v-if="block.title"
      once
      :style="`min-height: ${$q.screen.gt.sm ? 120 : 300}px`"
      @visibility="onVisibleTitle()"
    >
      <div class="text-center" ref="motionRefTitle">
        <h2
          :class="$q.screen.gt.sm ? 'tip-text-h2' : 'tip-text-h2-small'"
          class="q-mb-none q-mx-auto q-px-lg tip"
          style="max-width: 800px"
        >
          {{ block.title }}
        </h2>
      </div>
    </q-intersection>
    <div
      :class="$q.screen.gt.sm ? 'q-col-gutter-xl' : 'q-gutter-y-xl'"
      class="full-width items-stretch justify-center q-py-xl row"
    >
      <div
        v-for="(card, index) in block.cards"
        class="col-xs-11 col-md-3"
        :key="`card-${index}-${block._key}`"
      >
        <q-intersection
          once
          @visibility="onVisibleCard(index)"
          style="min-height: 275px"
        >
          <div :ref="(el) => (cardRefs[index] = el)">
            <q-card class="bg-secondary" flat style="min-height: 275px">
              <q-card-section>
                <q-card-section
                  v-if="card.iconPath?.length"
                  class="items-stretch row"
                >
                  <div class="col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      viewBox="0 0 64 64"
                      height="82px"
                      width="82px"
                    >
                      <g>
                        <g :id="`card-${index}-${block._key}`">
                          <g>
                            <path
                              v-for="(path, pIndex) in card.iconPath"
                              :key="`path-${pIndex}-${index}`"
                              :d="path"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div class="col">
                    <image-component :image="card.image" :ratio="1" />
                  </div>
                </q-card-section>
                <q-card-section class="text-h6" style="line-height: 1.125">
                  {{ card.body }}
                </q-card-section>
              </q-card-section>
            </q-card>
          </div>
        </q-intersection>
      </div>
    </div>
  </div>
</template>

<script setup>
import { nextTick, reactive, ref } from "vue";
import { storeToRefs } from "pinia";
import { useToUp } from "src/composables/animation/useToUp";
import { usePreferencesStore } from "src/stores/preferences";
import ImageComponent from "src/components/image/ImageComponent.vue";

defineOptions({ name: "CardBlocks" });

const props = defineProps({
  block: {
    type: Object,
    default: () => ({
      cards: new Array(3).fill({ body: null, iconPath: null }),
      subtitle: "",
      title: "",
    }),
  },
});

const preferencesStore = usePreferencesStore();
const { motion } = storeToRefs(preferencesStore);
const motionRefTitle = ref(null);
const cardRefs = reactive(new Array(props.block.cards.length));

const { setupMotion: setupMotionTitle } = useToUp(motionRefTitle, 0);

const onVisibleCard = async (index) => {
  await nextTick();
  if (motion.value && cardRefs[index]) {
    const motionInstance = useToUp(cardRefs[index], 50 * index);
    motionInstance.setupMotion().apply("enter");
  }
};

const onVisibleTitle = () => {
  if (motion.value) {
    const motionInstance = setupMotionTitle();
    motionInstance.apply("enter");
  }
};
</script>
