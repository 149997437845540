<template>
  <swiper-container
    init="false"
    ref="swiper"
    @mouseenter="onMouseEnter()"
    @mouseleave="onMouseLeave()"
  >
    <swiper-slide
      v-for="(slide, index) in slides"
      :key="`slide-${index}`"
      :style="slideStyle"
    >
      <div
        class="swiper-material-wrapper flex flex-center"
        :style="effect === 'tinder' ? 'overflow: visible' : null"
      >
        <div class="swiper-material-content full-height">
          <image-component
            v-if="type === 'image'"
            data-swiper-material-scale="1.25"
            :image="slide"
          />
          <q-card
            v-else-if="type === 'list'"
            class="bg-accent full-height"
            flat
            :style="`margin-top: 200px; width: ${$q.screen.gt.sm ? 5 : 3}40px`"
          >
            <q-card-section
              :class="[
                $q.screen.gt.sm ? 'q-pa-xl text-h4' : 'q-pa-lg text-h5',
                textColor,
              ]"
              class="full-height flex flex-center text-center"
              style="line-height: 1.125"
              >{{ slide }}</q-card-section
            >
          </q-card>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import {
  Autoplay,
  EffectCoverflow,
  EffectMaterial,
  EffectTinder,
} from "src/boot/swiper";
import { isColorDark } from "src/composables/useRandomPalette";
import { useSettingsStore } from "src/stores/settings";
import ImageComponent from "src/components/image/ImageComponent.vue";

defineOptions({ name: "SwiperComponent" });

const props = defineProps({
  autoHeight: { type: Boolean, default: false },
  auto: { type: Boolean, default: true },
  autoplay: {
    type: [Object, Boolean],
    default: () => ({
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    }),
  },
  centeredSlides: { type: Boolean, default: true },
  effect: { type: String, default: "material" },
  loop: { type: Boolean, default: true },
  slideHeight: { type: [Boolean, String], default: false },
  slideRatio: { type: [Boolean, String], default: false },
  slideWidth: { type: [Boolean, String], default: false },
  slides: { type: Array, default: () => new Array(4).fill({}) },
  slidesPerView: { type: [String, Number], default: "auto" },
  spaceBetween: { type: Number, default: 24 },
  speed: { type: Number, default: 2000 },
  type: { type: String, default: "image" },
});

const settingsStore = useSettingsStore();
const { selectedPalette } = storeToRefs(settingsStore);
const swiper = ref(null);
const textColor = ref("dark");
let autoplayInterval = null;

const computedAuto = computed(() => props.auto);

const computedSlides = computed(() => props.slides);

const slideStyle = computed(() => {
  let styles = {};
  if (props.slideHeight) {
    styles.height = props.slideHeight;
  }
  if (props.slideWidth) {
    styles.width = props.slideWidth;
  }
  return styles;
});

const params = {
  autoHeight: props.autoHeight,
  autoplay: props.autoplay,
  centeredSlides: props.centeredSlides,
  effect: props.effect,
  fade: props.fade,
  freeMode: props.freeMode,
  grabCursor: true,
  injectStylesUrls: [
    "swiper/modules/autoplay-element.min.css",
    "swiper/modules/effect-coverflow-element.min.css",
    "swiper/modules/effect-fade-element.min.css",
  ],
  loop: props.loop,
  materialEffect: {
    slideSplitRatio: 0.65,
  },
  modules: [Autoplay, EffectCoverflow, EffectMaterial, EffectTinder],
  slidesPerView: props.slidesPerView || "auto",
  spaceBetween: props.spaceBetween,
  speed: props.speed,
  on: {
    init(swiper) {
      if (props.effect === "tinder") {
        createAutoplay(swiper);
      }
    },
  },
};

const createAutoplay = (swiper) => {
  autoplayInterval = setInterval(() => {
    const isEven = swiper.realIndex % 2 === 0;
    isEven ? swiper.tinder.yes() : swiper.tinder.no();
  }, props.autoplay.delay);
};

const handleTextColor = () => {
  textColor.value = isColorDark(selectedPalette.value.accent?.hex || "#000000")
    ? "text-white"
    : "text-dark";
};

const onMouseEnter = () => {
  if (autoplayInterval) clearInterval(autoplayInterval);
  if (swiper.value) swiper.value.swiper.autoplay.stop();
};

const onMouseLeave = () => {
  if (!computedAuto.value) return;
  createAutoplay(swiper.value.swiper);
  if (swiper.value) swiper.value.swiper.autoplay.start();
};

onMounted(() => {
  handleTextColor();
  Object.assign(swiper.value, params);
  nextTick(() => {
    if (swiper.value) {
      swiper.value.initialize();
    }
  });
});

onUnmounted(() => {
  if (autoplayInterval) clearInterval(autoplayInterval);
});

watch(computedAuto, (newAuto) => {
  if (!swiper.value) return;
  if (newAuto) {
    swiper.value.swiper.autoplay.start();
  } else {
    swiper.value.swiper.autoplay.stop();
  }
});

watch(computedSlides, (newSlides) => {
  if (swiper.value && newSlides)
    nextTick(() => {
      swiper.value.swiper.update();
    });
});

watch(
  selectedPalette,
  () => {
    handleTextColor();
  },
  { deep: true }
);
</script>
