<template>
  <div class="text-center">
    <section-titles
      show-tip
      :subtitle="t('cta.subtitle')"
      :title="t('cta.title')"
    />
    <dialog-button alt-label />
  </div>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import { useDialogStore } from "src/stores/dialog";
import DialogButton from "src/components/dialog/DialogButton.vue";
import SectionTitles from "src/components/section/SectionTitles.vue";

defineOptions({ name: "CtaBanner" });

const { t } = i18n.global;

const dialogStore = useDialogStore();

const onClick = () => {
  dialogStore.show("contact", {});
};
</script>
